<template>
  <div class="container-fluid card-body">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col-12">
        <FullCalendar
            class='demo-app-calendar'
            ref="fullCalendar"
            defaultView="dayGridMonth"
            :header="{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            }"
            :eventTextColor="'#FFFFFF'"
            :slotLabelFormat="labelFormat"
            :locale="'pt-br'"
            :plugins="calendarPlugins"
            :weekends="calendarWeekends"
            :events="calendarEvents"
            :buttonText="buttonsText"
            @dateClick="newEvent"
            @eventClick="clickEvent"
            />
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
export default {
  components: {
    FullCalendar
  },
  data () {
    return {
      isLoading: false,
      buttonsText: {
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        list: 'Lista'
      },
      labelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: false
      },
      calendarPlugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
      calendarWeekends: true,
      calendarEvents: []
    }
  },
  methods: {
    newEvent (arg) {
      console.log(arg)
    },
    clickEvent (arg) {
      console.log(arg)
    }
  }
}
</script>

<style scoped>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
</style>